/*
    #F1F2F2 - fundo complementar
    #888282 - texto claro
*/

@font-face {
    font-family: "Provicali";
    src: url("./fonts/Provicali.otf");
}

@font-face {
    font-family: "RobotoBold";
    src: url("./fonts/Roboto-Bold.ttf");
}

html{
    scroll-behavior: smooth;
    -webkit-overflow-scrolling:touch;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
}

h1, h2, h4, h5{
    font-family: "RobotoBold";
}

h3{
    font-family: "Provicali";
    color: #8C8C8C;
}