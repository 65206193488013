/* Ajustes gerais do header */
.header {
    display: flex;
    align-items: center; /* Alinha itens verticalmente ao centro */
    justify-content: space-between; /* Distribui espaço entre itens */
    width: 100%;
    height: 66px; /* Altura do header */
    padding: 0 20px;
    background: #fff;
    position: fixed; /* Mantém o header fixo no topo */
    top: 0;
    left: 0;
    z-index: 99;
}

/* Ajuste da classe toogleIsoDiv */
.toogleIsoDiv {
    display: flex;
    align-items: center; /* Alinha verticalmente ao centro */
    justify-content: center; /* Alinha horizontalmente ao centro */
    margin-left: 10px;
    height: 100%; /* Garante que o container ocupe toda a altura do header */
}

/* Ajuste do botão toggleIso */
.toogleIsoDiv .toggleIso {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    height: 100%; /* Garante que o botão ocupe toda a altura do container */
    cursor: pointer; /* Muda o cursor para indicar que o botão é clicável */
}

/* Estado hover do botão */
.toogleIsoDiv .toggleIso:hover {
    background: transparent;
}

/* Estado active do botão */
.toogleIsoDiv .toggleIso:active {
    background: transparent;
    border: none;
}

/* Ajustes responsivos para garantir que o botão fique ao lado do "Transparência" */
@media only screen and (max-width: 1000px) {
    .header .menu {
        display: none;
        flex-direction: row;
        align-items: center;
        margin: 0; /* Remove qualquer margem adicional */
    }
    
    .header .menuGrande {
        display: none;
        flex-direction: row;
        align-items: center; /* Alinha itens verticalmente ao centro */
        justify-content: flex-start; /* Alinha os itens ao início */
        width: calc(100% - 10px); /* Ajusta a largura para deixar espaço para o botão Iso */
    }
    
    .header .menuGrande li {
        margin: 0 10px; /* Adiciona margem horizontal aos itens */
    }
    
    .toogleIsoDiv {
        margin-left: 20px; /* Ajusta a margem conforme necessário */
    }
}
