/* Definição da área de trabalho */
.App{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow-x: hidden;
}

/* Definição do topo */

.header{
    width: 100%;
    height: 66px;
    padding: 0 20px 0 20px;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    position: absolute;
    background: #fff;
}

.header .iso{
    width: 120px;
    height: 60px;
    margin-top: 5px;
}

/* Logo */

.header .logo{
    width: 250px;
    height: 100%;
    display: flex;
    align-items: center;
}

.header .logob{
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    margin-top: -5px;
    background: #ffffffaa;
    border-radius: 45px;
}

.imgLogo{
    width: 200px;
    height: 50px;
}

/* Menu */
.menuGrande{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.header ul{
    list-style: none;
    padding: 0;
    margin: 0;
    width: calc(100% - 200px);
    height: 100%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.header a{
    margin-left: 10px;
    text-decoration: none;
}

.header li{
    height: 60px;
    padding: 0 21px 0 20px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.menuGrande a li{
    text-decoration: none;
    color: #3E4095;
    text-transform: capitalize;
    font-size: small;
}

.menuGrande a:hover{
    text-decoration: none;
}

.menuGrande a li:hover{
    color: #6164E8;
    border-bottom: 2px solid #6164E8;
}

/* Renderização responsiva do menu */

@media only screen and (max-width: 1000px) {
    .menuHidden {
      display: none;
    }
    .header .logo{
        display: none;
        width: 80px;
    }
}

@media only screen and (min-width: 1000px) {
    .header .logob{
        display: none;
    }
    .toggleMenu{
        display: none;
    }
}

.toggleMenu{
    position: fixed;
    z-index: 1;
    right: 20px;
    top: 20px;
}

@media only screen and (max-width: 1000px) {
    .toggleMenu{
        z-index: 99;
    }
    .header .logob{
        position: fixed;
        top: 17px;
    }
}

/* Botão pro topo */

.btTop{
    position: fixed;
    z-index: 3;
    bottom: 0;
    right: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    font-size: xx-large;
    display: flex;
    flex-direction: column;
}

.whatsButton{
    border: none;
    color: #fff;
    font-size: 20px;
    background: #fff;
    width: 36px;
    height: 36px;
    border-radius: 28px;
    background: #75c043;
    border: 2px solid #fff;
}

.whatsButton:hover{
    transform: rotate(360deg);
    -webkit-transition: all 0.8s 0s ease;
    -moz-transition: all 0.8s 0s ease;
    -o-transition: all 0.8s 0s ease;
    transition: all 0.8s 0s ease;
}

.linkedinButton{
    border: none;
    color: #fff;
    font-size: 20px;
    background: #fff;
    width: 36px;
    height: 36px;
    border-radius: 28px;
    background: #0A66C2;
    margin-bottom: 15px;
    border: 2px solid #fff;
}

.linkedinButton:hover{
    transform: rotate(360deg);
    -webkit-transition: all 0.8s 0s ease;
    -moz-transition: all 0.8s 0s ease;
    -o-transition: all 0.8s 0s ease;
    transition: all 0.8s 0s ease;
}

/* Conteúdo */
.content{
    width: 100%;
}

/* Rodapé */
.footer{
    width: 100%;
}

.footer .footerContent{
    padding: 40px 0 30px 0;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer .footerContent .imgfooter{
    width: 25%;
}

.footer .footerContent .logofooter{
    width: 200px;
    height: 45px;
}

.footer .footerContent .box{
    width: 25%;
}

.footer .footerContent .box ul{
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 20px;
    color: #242424;
}

.footer .footerContent .box ul li{
    margin-top: 10px;
}

.footer .footerContent .box3 ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer .footerContent .box3 ul li i{
    margin-right: 15px;
    font-size: large;
}

.footer a{
    color: #666;
}

.footer a:hover{
    text-decoration: none;
}

.termo{
    position: fixed;
    z-index: 99;
    bottom: 0;
    width: 100%;
    background-color: transparent;
}

@media only screen and (max-width: 800px) {
    .footer{
        font-size: small;
    }

    .footer h3{
        font-size: 24px;
    }

    .footer .footerContent .imgfooter{
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid #3E4095;
    }
    
    .footer .footerContent .box{
        width: 100%;
        margin-top: 30px;
        padding-left: 20px;
    }
}
