.objetivos{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    
}

 .objetivos h1{
    width: 100%;
    color: #3e4095;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    
}

 .objetivos h1 span{
    color: #3F44FE;
}

  .cardsItems{
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

 .cardsItems .Item{
    width: 23%;
    padding: 30px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
}

 .cardsItems .Item p{
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

 .cardsItems .item1{
    background:
   
        url(../../imgs/treinamento1.jpg);
        background-size: cover;
        background-position: center;
}

  .cardsItems .item2{
    background:
      
        url(../../imgs/treinamento4.jpeg);
        background-size: cover;
        background-position: center;
}

 .cardsItems .item3{
    background:
       
        url(../../imgs/treinamento3.jpg);
        background-size: cover;
        background-position: center;
}

 .cardsItems .item4{
    background:
     
        url(../../imgs/treinamento15.jpeg);
        background-size: cover;
        background-position: center;
}

.cardsItems .item5{
    background:
     
        url(../../imgs/treinamento15.jpeg);
        background-size: cover;
        background-position: center;
}


 .cardsItems .item1:hover{
    background:
       
    url(../../imgs/treinamento1.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color:#D8C571;
        cursor:context-menu;
}

 .cardsItems .item2:hover{
    background:
     
    url(../../imgs/treinamento4.jpeg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color:#D8C571;
        cursor:context-menu;
}

.cardsItems .item3:hover{
    background:
        
    url(../../imgs/treinamento3.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color:#D8C571;
        cursor:context-menu;
}

 .cardsItems .item4:hover{
    background:
       
    url(../../imgs/treinamento15.jpeg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}




.cardsItems .item6:hover{
    background:
       
    url(../../imgs/PREMIO2.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}


.cardsItems .item7:hover{
    background:
      
    url(../../imgs/PREMIO3.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}

 .cardsItems .item8:hover{
    background:
    
    url(../../imgs/PREMIO4.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}

@media only screen and (max-width: 800px) {
   
    .cardsItems .Item{
        width: 100%;
    }
}