.Contato{
    background:
        linear-gradient(
        #ffffffaa,#ffffffaa
        ),
        url(../../imgs/fundo.jpg);
        background-size: cover;
        background-position: bottom;
}



.hide{
    display: none;
}

.Buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Buttons button{
    width: 40%;
    background: #3e4095;
    color: #fff;
    border: solid 2px #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    padding: 20px 5px;
    border-radius: 0 0 15px 15px;
}

.Buttons button i{
    font-size: xx-large;
    margin-bottom: 15px;
}

.forms h1{
    color: #3e4095;
    text-transform: uppercase;
    font-size: xx-large;
}

.line{
    height: 2px;
    width: 70%;
    background: #3e4095;
    margin-bottom: 15px;
    margin-left: -15px;
}

.Informacoes .infos{
    padding: 20px;
    border: 1px solid #ccc;
    background: #ffffff66;
}

.Informacoes .infos .box i{
    padding: 10px 0;
}

.Informacoes .infos .box i{
    color: #3e4095;
    width: 30px;
}

.localMap{
    margin-top: 20px;
    border: none;
    width: 100%;
    height: 200px;
}

@media only screen and (max-width: 800px) {
    .Buttons button{
        font-size: 9px;
    }
}