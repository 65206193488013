
.Sustentabilidade .meioambiente{
    width: 100%;
    padding: 90px 10% 90px 10%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin: 0;
}

.Sustentabilidade .meioambiente h1{
    text-align: center;
    width: 80%;
    margin-bottom: 20px;
}

.Sustentabilidade h4{
    width: 80%;
    text-align: center;
    color: #fff;
    font-family: "Montserrat";
    font-size: small;
    line-height: 2rem;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 30px;
    text-transform: uppercase;
}


.Sustentabilidade .meioambiente h5{
    text-align: center;
    width: 80%;
    line-height: 34px;
}

.Sustentabilidade .objetivos{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Sustentabilidade .objetivos h1{
    width: 100%;
    color: #3e4095;
    text-align: center;
    margin-bottom: 30px;
}

.Sustentabilidade .objetivos h1 span{
    color: #3F44FE;
}

.Sustentabilidade  .cardsItems{
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Sustentabilidade .cardsItems .Item{
    width: 23%;
    padding: 30px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
}

.Sustentabilidade  .cardsItems .Item p{
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

.Sustentabilidade  .cardsItems .item1{
    background:
   
        url(../../imgs/seguranca1.jpeg);
        background-size: cover;
        background-position: center;
}

.Sustentabilidade  .cardsItems .item2{
    background:
      
        url(../../imgs/segurança2.png);
        background-size: cover;
        background-position: center;
}

.Sustentabilidade .cardsItems .item3{
    background:
       
        url(../../imgs/treinamento14.jpeg);
        background-size: cover;
        background-position: center;
}

.Sustentabilidade  .cardsItems .item4{
    background:
     
        url(../../imgs/seguranca3.jpeg);
        background-size: cover;
        background-position: center;
}



.Sustentabilidade .cardsItems .item1:hover{
    background:
        linear-gradient(
        #2A3950dd,#2A3950dd
        ),
        url(../../imgs/meioambienteum.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color:#D8C571;
        cursor:context-menu;
}

.Sustentabilidade .cardsItems .item2:hover{
    background:
        linear-gradient(
        #2A3950dd,#2A3950dd
        ),
        url(../../imgs/meioambiente2.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color:#D8C571;
        cursor:context-menu;
}

.Sustentabilidade .cardsItems .item3:hover{
    background:
        linear-gradient(
        #2A3950dd,#2A3950dd
        ),
        url(../../imgs/meioambiente3.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color:#D8C571;
        cursor:context-menu;
}

.Sustentabilidade .cardsItems .item4:hover{
    background:
        linear-gradient(
        #2A3950dd,#2A3950dd
        ),
        url(../../imgs/meioambiente4.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}

.Sustentabilidade .cardsItems .item5:hover{
    background:
        linear-gradient(
        #2A3950dd,#2A3950dd
        ),
        url(../../imgs/meioambiente5.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}

.Sustentabilidade.cardsItems .item6:hover{
    background:
        linear-gradient(
        #2A3950dd,#2A3950dd
        ),
        url(../../imgs/meioambiente6.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}

.Sustentabilidade .cardsItems .item7:hover{
    background:
        linear-gradient(
        #2A3950dd,#2A3950dd
        ),
        url(../../imgs/meioambiente7.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}

.Sustentabilidade .cardsItems .item8:hover{
    background:
        linear-gradient(
        #2A3950dd,#2A3950dd
        ),
        url(../../imgs/meioambiente8.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}
@media only screen and (max-width: 800px) {
    .Sustentabilidade .cardsItems .Item{
        width: 100%;
    }
}
