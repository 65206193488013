.footer {
    background-color: #f8f9fa; /* Cor de fundo do footer */
    padding: 20px; /* Espaçamento interno do footer */
}

.footer a {
    text-decoration: none; /* Remove o sublinhado dos links */
    color: #3e4095; /* Cor dos links no footer */
}

.footer a:hover {
    text-decoration: none; /* Garante que o sublinhado não apareça ao passar o mouse */
}

/* Estilo dos títulos dentro do footer */
.footer h3 {
    color: #3e4095; /* Cor dos títulos */
}

/* Estilo da seção LGPD */
.lgpd {
    text-align: center; /* Centraliza o texto */
    color: #3e4095; /* Cor do texto */
    font-size: large; /* Tamanho da fonte */
    padding-top: 20px; /* Espaçamento superior */
    background: #F1F2F2; /* Cor de fundo */
}

/* Estilo da seção de dedicatória */
.dedicatoria {
    text-align: center; /* Centraliza o texto */
    color: #050513; /* Cor do texto */
    font-size: small; /* Tamanho da fonte */
    padding-top: 20px; /* Espaçamento superior */
    background: #F1F2F2; /* Cor de fundo */
}

/* Remove a margem inferior dos parágrafos na dedicatória */
.dedicatoria p {
    margin-bottom: 0; /* Remove a margem inferior */
}

/* Remove o sublinhado dos links dentro da dedicatória */
.dedicatoria a {
    text-decoration: none; /* Remove o sublinhado dos links */
    color: #060633; /* Cor dos links */
}

.dedicatoria a:hover {
    text-decoration: underline; /* Adiciona sublinhado ao passar o mouse se desejar */
}
