.mvv{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 8%;
    background: #F1F2F2;
    margin-top: 40px;
}

.mvv h3{
    color: #3e4095;
}

.mvv .mvvbox{
    width: 30%;
    text-align: center;
}

.mvv .mvvbox ul{
    padding: 0;
    margin: 0;
}

.mvv .mvvbox ul li{
    list-style: none;
}

.timeline{
    padding: 70px 8%;
}

.timeline h1{
    text-align: center;
    width: 100%;
    margin-bottom: 60px;
    color:#3e4095;
}

.timeline h1 span{
    color: #000000;
}

.objetivos{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    
}

 .objetivos h1{
    width: 100%;
    color: #3e4095;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    
}

 .objetivos h1 span{
    color: #3F44FE;
}

  .area{
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

 .area .Item{
    width: 30%;
    padding: 30px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
}

 .area.Item p{
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

 .area .item11{
    background:
   
        url(../../imgs/PREMIO5.jpg);
        background-size: cover;
        background-position: center;
}

  .area .item12{
    background:
      
        url(../../imgs/PREMIO6.jpg);
        background-size: cover;
        background-position: center;
}

 .area .item13{
    background:
       
        url(../../imgs/PREMIO7.jpg);
        background-size: cover;
        background-position: center;
}

 area .item14{
    background:
     
        url(../../imgs/PREMIO9.jpg);
        background-size: cover;
        background-position: center;
}

 .area .item5{
    background:
     
        url(../../imgs/PREMIO1.jpg);
        background-size: cover;
        background-position: center;
}

.area .item6{
    background:
      
        url(../../imgs/PREMIO2.jpg);
        background-size: cover;
        background-position: center;
}

 .area .item7{
    background:
      
        url(../../imgs/PREMIO3.jpg);
        background-size: cover;
        background-position: center;
}

  .area .item8{
    background:
       
        url(../../imgs/PREMIO4.jpg);
        background-size: cover;
        background-position: center;
}

 .area .item1:hover{
    background:
       
    url(../../imgs/PREMIO5.jpg);
      background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color:#D8C571;
        cursor:context-menu;
}

 .area .item2:hover{
    background:
     
    url(../../imgs/PREMIO6.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color:#D8C571;
        cursor:context-menu;
}

.area .item3:hover{
    background:
        
    url(../../imgs/PREMIO7.jpg);
    background-size: cover;
    background-position: center;
    border: 1px solid #fff;
    color: #D8C571;
    cursor:context-menu;
}

 .area .item4:hover{
    background:
       
    url(../../imgs/PREMIO9.jpg);
    background-size: cover;
    background-position: center;
    border: 1px solid #fff;
    color: #D8C571;
    cursor:context-menu;
}

 .area .item5:hover{
    background:
       
    url(../../imgs/PREMIO1.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}

.area .item6:hover{
    background:
       
    url(../../imgs/PREMIO2.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}


.area .item7:hover{
    background:
      
    url(../../imgs/PREMIO3.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}

 .area .item8:hover{
    background:
    
    url(../../imgs/PREMIO4.jpg);
        background-size: cover;
        background-position: center;
        border: 1px solid #fff;
        color: #D8C571;
        cursor:context-menu;
}




@media only screen and (max-width: 800px) {
    .mvv .mvvbox{
        width: 100%;
    }

    .visao, .valores{
        margin-top: 30px;
    }
    .area .Item{
        width: 100%;
    }
}


