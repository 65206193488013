@keyframes fade{
    from{opacity: 0;}
    to{opacity: 1;}
}

.tabs{
    width: 90%;
    margin-left: 5%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-top: 5px solid #36363B;
    flex-wrap: wrap;
}

.tabs button{
    border: none;
    background: transparent;
    color: #36363B;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.marcado{
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #3F44FE;
    border: 2px solid #36363B outset;
    margin-top: -12px;
    margin-bottom: 10px;
}

.naomarcado{
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #102C5D;
    border: 2px solid #36363B;
    margin-top: -12px;
    margin-bottom: 10px;
}

.contentTab{
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    padding: 25px;
    text-align: center;
}

.contentTab *{
    animation-name: fade;
    animation-duration: 1s;
}

.contentTab h2{
    margin-bottom: 20px;
}

.contentTab p{
    color: #36363B;
    font-size: medium;
}

.contentTab img{
    margin-top: 20px;
    margin-bottom: 20px;
}

.tab2018content{
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    flex-wrap: wrap;
}

.tab2018content .tab2018a{
    width: 45%;
}

.tab2018content .tab2018a img{
    width: 100%;
    height: auto;
}

.tab2018content .tab2018b{
    width: 45%;
}

.tab2018content .tab2018b img{
    width: 100%;
    height: auto;
}

.tab2018content .tab2021{
    width: 45%;
}

.tab2018content .tab2021 img{
    width: 100%;
    height: auto;
}


.tab2021content{
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    flex-wrap: wrap;
}

.tab2021content .tab2021a{
    width: 45%;
}

.tab2021content .tab2021a img{
    width: 100%;
    height: auto;
}

.tab2021content .tab2021b{
    width: 45%;
    align-items: center;
}

.tab2021content .tab2021b img{
    width: 100%;
   
    height: auto;
   
}

.tab2021content .tab2021{
    width: 45%;
}

.tab2021content .tab2021 img{
    width: 100%;
    height: auto;
}



.controls{
    animation: none;
    width: 80%;
    position: absolute;
    left: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



.controls button{
    width: 30px;
    height: 60px;
    border: 1px solid #102C5D;
    background: transparent;
}

.controls button i{
    font-size: x-large;
    color: #102C5D;
}

@media only screen and (max-width: 800px) {
    .tabs{
        width: 60px;
        margin-left: 50%;
        height: 100%;
        flex-direction: column;
        border-left: 5px solid #36363B;
        border-top: none;
        flex-wrap: wrap;
    }

    .tabs button{
        border: none;
        color: #36363B;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    .marcado{
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background: #3F44FE;
        border: 2px solid #36363B outset;
        margin: 0;
        margin-left: -17px;
        margin-right: 10px;
    }
    
    .naomarcado{
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background: #102C5D;
        border: 2px solid #36363B;
        margin: 0;
        margin-left: -17px;
        margin-right: 10px;
    }

    .tab2018content .tab2018a{
        width: 100%;
    }
    
    .tab2018content .tab2018b{
        width: 100%;
    }

    .imgPane{
        width: 250px;
        height: auto;
    }
}