/* Estilo para a seção Home */
.Home {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Adiciona um pouco de espaçamento ao redor do carrossel */
    background-color: #f9f9f9; /* Cor de fundo opcional */
  }
  
  /* Estilo para o componente Carousel */
  .carousel-container {
    width: 100%;
    max-width: 1200px; /* Ajuste conforme necessário */
    margin: 0 auto; /* Centraliza o carrossel */
  }
  
  /* Ajuste o tamanho e a aparência das imagens do carrossel */
  .carousel .slide img {
    width: 100%; /* Garante que a imagem ocupa toda a largura do slide */
    height: auto; /* Mantém a proporção da imagem */
    object-fit: cover; /* Ajusta a imagem para cobrir o slide */
  }
  
  /* Estilos adicionais para o carrossel */
  .carousel .control-arrow {
    background-color: #3e4095; /* Cor dos botões de navegação */
    border-radius: 50%;
    width: 40px; /* Largura dos botões */
    height: 40px; /* Altura dos botões */
    color: white; /* Cor do ícone */
  }
  
  .carousel .control-arrow:hover {
    background-color: #6164e8; /* Cor de fundo quando o botão é hover */
  }
  
  .carousel .carousel-status {
    display: none; /* Oculta o status da navegação, se não for necessário */
  }
  
  .carousel .carousel-indicators {
    bottom: 10px; /* Ajuste a posição dos indicadores */
  }
  
  .carousel .carousel-indicators .dot {
    background-color: #3e4095; /* Cor dos pontos de indicador */
  }
  
  .carousel .carousel-indicators .dot.active {
    background-color: #6164e8; /* Cor do ponto ativo */
  }
  
  /* Estilos de mídia para dispositivos menores */
  @media only screen and (max-width: 768px) {
    .carousel-container {
      max-width: 100%;
      padding: 0 10px; /* Adiciona espaçamento lateral em dispositivos móveis */
    }
  }
  