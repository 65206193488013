/* Menu grande (desktop) */
.menuGrande {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

/* Esconder o menu grande em telas menores */
@media only screen and (max-width: 1000px) {
    .menuGrande {
        display: none; /* Esconde o menu grande em telas pequenas */
    }
}

/* Menu alternativo (mobile) */
.menuToggle {
    display: none; /* Esconde por padrão */
    padding: 0;
    margin: 0;
    background: linear-gradient(180deg, #ffffffaa 0%, #ffffffee 100%);
    position: absolute;
    right: 0;
    top: 66px;
    width: 300px;
    z-index: 99;
}

/* Mostrar menu alternativo em telas pequenas */
@media only screen and (max-width: 1000px) {
    .menuToggle {
        display: block; /* Mostra o menu alternativo em telas pequenas */
    }
}

.menuToggle a {
    width: 100%;
    text-decoration: none;
}

.menuToggle ul {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 15px;
}

.menuToggle a li {
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
