.SecondaryText{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #EFEFEF;
    padding: 50px 8%;
    justify-content: space-between;
}



.SecondaryText .imgField{
    width: 45%;
}

.SecondaryText .textField{
    width: 45%;
}

.imgpoliticaQualidade{
    background: url(../imgs/politica.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.imggestaoQualidade{
    background: url(../imgs/gestao.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.imgresponsabilidade{
    background: url(../imgs/responsabilidadesocial.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.imgetica{
    background: url(../imgs/etica.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.SecondaryText .textField h1{
    margin-bottom: 20px;
}

.SecondaryText h3 {
    margin-bottom: 20px;
    color:#2c2e82;
}

.SecondaryText .textField ul{
    
    list-style: none;
    padding: 0;
    margin: 0;
}

.SecondaryText .textField ul li{
    margin-top: 15px;
}


@media only screen and (max-width: 800px) {
    .SecondaryText .imgField{
        width: 100%;
        height: 300px;
        margin-bottom: 30px;
    }
    
    .SecondaryText .textField{
        width: 100%;
    }

    .SecondaryText .textField h1{
        text-align: center;
    }
}