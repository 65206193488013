form{
    border: 1px solid #ccc;
    padding: 25px;
}

form label{
    width: 100%;
    font-size: small;
}

form input{
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 0 4px 4px 0;
    padding: 4px 8px;
}

form input button{
    background: #fff;
}

form textarea{
    width: 100%;
    margin-bottom: 15px;
    height: 70px;
    border: 1px solid #ccc;
    border-radius: 0 4px 4px 0;
    padding: 4px 8px;
}

form button {
    border: none;
    font-size: 10px;
    font-weight: bold;
    color: #3F44FE;
    background: #fff;
    border: 1px solid #ccc;
    text-transform: uppercase;
    width: 90px;
    height: 30px;
    border-radius: 0 4px 4px 0;
}

input[type='file'] {
    font-size: medium;
    padding: 0;
}