.content-section {
    padding-bottom: 40px;
    width: 100%;
}

.content-section .title {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
    position: relative;
}

.content-section .title h1 {
    font-size: 25px; /* Aumente o tamanho do texto do título */
    background: #3e4095;
    color: #fff;
    padding: 20px;
    border-radius: 0 20px 20px 0;
    margin: 0;
    display: inline-block;
    width: 100%; /* Título ocupa 50% da largura da tela */
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(0%);
    z-index: 2; /* Garante que o título fique acima do fundo */
}

.content-section .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    padding-top: 90px; /* Ajuste para compensar a altura do título */
}

.content-section .image-container {
    display: flex;
    flex-direction: column; /* Alinha as imagens verticalmente */
    align-items: center;
    gap: 20px; /* Espaçamento entre as imagens */
    margin-top: 20px;
}


.content-section .image-container img {
    max-width: 100%; /* Define que a largura da imagem ocupa 100% do contêiner */
    height: auto;
    border-radius: 8px;
}

.content-section .text-content {
    width: 100%;
    max-width: 900px; /* Ajuste conforme necessário */
    text-align: center;
    padding: 0 20px;
}

.content-section .text-content p {
    color: #fff;
    font-size: 18px; /* Aumente o tamanho do texto do conteúdo */
    line-height: 1.5; /* Ajuste a altura da linha para melhor legibilidade */
    background: #3e4095; /* Cor de fundo da caixa */
        border-radius: 8px; /* Bordas arredondadas */
    padding: 20px; /* Espaçamento interno */
    margin: 20px 0; /* Espaçamento entre parágrafos */
}

@media only screen and (max-width: 800px) {
    .content-section .title h1 {
        width: 90vw; /* Ajusta a largura do título para telas menores */
        left: 5%;
        font-size: 36px; /* Ajusta o tamanho do texto do título para telas menores */
    }

    .content-section .text-content p {
        font-size: 16px; /* Ajusta o tamanho do texto do conteúdo para telas menores */
    }

    .content-section .image-container img {
        height: auto;
    }
}
