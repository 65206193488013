.img{
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
}

.prev{
    margin-left: -5%;
    height: 300px;
    margin-top: calc(45vh - 150px);
}

.next{
    margin-right: -5%;
    height: 300px;
    margin-top: calc(45vh - 150px);
}

.carouselHome{
    width: 100%;
    position: relative;
    z-index: 2;
}

.fundogaleria{
    width: 100%;
    height: 100vh;
}




.img0{
    background:
        linear-gradient(
        #000000aa,#000000aa
        ),
        url(../imgs/home9.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
}
.img1{
    background:
        linear-gradient(
        #000000aa,#000000aa
        ),
        url(../imgs/home8.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
}

.img2{
    background:
        linear-gradient(
        #000000aa,#000000aa
        ),
        url(../imgs/home7.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
}
.img3{
    background:
        linear-gradient(
        #000000aa,#000000aa
        ),
        url(../imgs/home6.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
}
.img4{
    background:
        linear-gradient(
        #000000aa,#000000aa
        ),
        url(../imgs/003.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
}
.img5{
    background:
        linear-gradient(
        #000000aa,#000000aa
        ),
        url(../imgs/004.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
}
.Home h3{
    font-size: 50px;
    color: #fff;
    font-weight: 400;
    letter-spacing: 3px;
}

.Home p{
    font-size: 24px;
    color: #fff;
}

.textoGaleria{
    
    width: 70%;
    height: 100vh;
    position: absolute;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    top: 50%;
}

@media only screen and (max-width: 800px) {
    .img0{
        background: url(../imgs/001.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
    }
    
    .img1{
        background: url(../imgs/002.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
    }
}