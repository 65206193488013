.top{
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding-top: 70px;
}

.top .content{
    width: auto;
}

.top h2{
    width: 100%;
    font-size: 50px;
    text-align: center;
    padding: 0 30px;
}

.top p{
    width: 100%;
    text-align: center;
    font-size: large;
    padding: 0 30px;
}

.linha{
    height: 5px;
    background: #fff;
    border-radius: 5px;
    margin: 15px 30px;
}

.institucional{
    background:
        linear-gradient(
        #000000aa,#000000aa
        ),
        url(../imgs/fundoInst.jpg);
        background-size: cover;
        background-position: top;
        background-attachment: fixed;
}

.servicos{
    background:
        linear-gradient(
        #000000aa,#000000aa
        ),
        url(../imgs/servicos.jpg);
        background-size: cover;
        background-position: top;
        background-attachment: fixed;
}

.pessoas{
    background:
        linear-gradient(
        #000000aa,#000000aa
        ),
        url(../imgs/pessoas.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
}

.seguranca{
    background:
        linear-gradient(
        #000000aa,#000000aa
        ),
        url(../imgs/seguranca.jpg);
        background-size: cover;
        background-position: top;
        background-attachment: fixed;
}

.sustentabilidade{
    background:
        linear-gradient(
        #000000aa,#000000aa
        ),
        url(../imgs/sustentabilidade.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
}

.contato{
    background:
        linear-gradient(
        #000000aa,#000000aa
        ),
        url(../imgs/contato.jpg);
        background-size: cover;
        background-position: bottom;
        background-attachment: fixed;
}

.LGPD{
    background:
        linear-gradient(
            #000000aa,#000000aa
        ),
        url(../imgs/image_lgpd.jpg);
        background-size: cover;
        background-position: bottom;
        background-attachment: fixed;
}
.portal{
    background:
        linear-gradient(
            #000000aa,#000000aa
        ),
        url(../imgs/home8.jpg);
        background-size: cover;
        background-position: bottom;
        background-attachment: fixed;
}




@media only screen and (max-width: 800px) {
    .top h2{
        font-size: 40px;
    }
}