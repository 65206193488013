/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #bbb; 
  }

.atuacao{
    padding: 0;
    margin-top: -1px;
    width: 100%;
    
}


.atuacao h1{

    height: 70px;
    display: flex;
    align-items: center;
    background:#3e4095;
    padding: 0;
    margin: 0;
    
    padding-left: 8%;
    border-radius: 0 20px 20px 0;
    font-size: 32px;
    color: #fff;
}

.atuacao h1 span{
    color: #000177;
    margin-left: 10px;
}

.areas{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 110px;
}

.areas .areaBox{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -60px;
    margin-bottom: 60px;
    }

    .areas .areaBox1{
        background: url(../../imgs/naval.png);
        
        width: 120px;
    height: 120px;
    
    color: #3e4095;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .areas .areaBox2{
        background: url(../../imgs/automobilistico.png);
       
        width: 120px;
    height: 120px;
 
    color: #3e4095;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .areas .areaBox3{
        background: url(../../imgs/papel-celulose.png);
        
        width: 120px;
        height: 120px;
      
        color: #3e4095;
        font-size: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .areas .areaBox4{
        background: url(../../imgs/portuario.png);
       
        width: 120px;
        height: 120px;
      
        color: #3e4095;
        font-size: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .areas .areaBox5{
        background: url(../../imgs/quimico-petroquimico.png);
      
        width: 120px;
        height: 120px;
      
        color: #3e4095;
        font-size: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .areas .areaBox6{
        background: url(../../imgs/alimenticio.png);
        width: 120px;
        height: 120px;
        
        color: #3e4095;
        font-size: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .areas .areaBox7{
        background: url(../../imgs/sucroenergetico-agronegocio.png);
      
        width: 120px;
        height: 120px;
       
        color: #3e4095;
        font-size: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .areas .areaBox8{
        background: url(../../imgs/off-shore.png);
       
        width: 120px;
    height: 120px;
   
    color: #3e4095;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
.areas .areaBox i{
    background: c;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    color: #fff;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.areas .areaBox h5{
    width: 100%;
    text-align: center;
    margin-top: 150px;
    margin-bottom: 35px;
}




.prestados{
    background: #EFEFEF;
    padding: 60px 0;
}

.prestados h1{
    text-align: center;
    color: #2c2e82;
    margin-bottom: 40px;
}

.prestados h1 span{
    color: #3F44FE;
}


.slider{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}


.slider .item{
    width: 23%;
    height: 200px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 10px;
}

.slider .item h5{
    color: #fff;
 
    width: 100%;
    text-align: center;
    font-size: small;
    font-weight: bold;
    padding: 10px 10px;
}

.slider .item1{
    background: url(../../imgs/1.jpg);
    background-size: cover;
    background-position: center;
}

.slider .item2{
    background: url(../../imgs/2.jpg);
    background-size: cover;
    background-position: center;
}

.slider .item3{
    background: url(../../imgs/3.jpg);
    background-size: cover;
    background-position: center;
}

.slider .item4{
    background: url(../../imgs/4.jpg);
    background-size: cover;
    background-position: center;
}

.slider .item5{
    background: url(../../imgs/5.jpg);
    background-size: cover;
    background-position: center;
}

.slider .item6{
    background: url(../../imgs/6.jpg);
    background-size: cover;
    background-position: center;
}

.slider .item7{
    background: url(../../imgs/7.jpg);
    background-size: cover;
    background-position: center;
}

.slider .item8{
    background: url(../../imgs/10.jpg);
    background-size: cover;
    background-position: center;
}

.slider .item9{
    background: url(../../imgs/9.jpg);
    background-size: cover;
    background-position: center;
}
.slider .item10{
    background: url(../../imgs/11.jpg);
    background-size: cover;
    background-position: center;
}
.slider .item11{
    background: url(../../imgs/8.jpg);
    background-size: cover;
    background-position: center;
}
.slider .item12{
    background: url(../../imgs/12.jpeg);
    background-size: cover;
    background-position: center;
}
@media only screen and (max-width: 1000px) {
    .slider .item{
        width: 130px;
        height: 400px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-left: 12px;
        margin-right: 12px;
    }
    
    .slider .item h5{
        color: #fff;
     
        width: 130px;
        text-align: center;
        font-size: small;
        padding: 10px 10px;
    }
}


@media only screen and (max-width: 800px) {
    .atuacao h1{
        font-size: 28px;
    }

    .areas .areaBox{
        width: 100%;
    }
    .prestados{
        width: 100%;
    }
    .slider .item{
        width: 100%;
    }
}