.TextField{
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 40px;
}

.TextField .title{
    
    width: 100%;
    margin-top: -26px;
    margin-bottom: 30px;
}

.TextField .title h1{
    height: 70px;
    display: flex;
    align-items: center;
    background:#3e4095;
    padding: 0;
    margin: 0;
    
    padding-left: 8%;
    border-radius: 0 20px 20px 0;
    font-size: 32px;
    color: #fff;
}

.TextField .title .institucionalFoto{
    width: 25%;
}

.TextField .title .contato{
    width: 35%;
}

.TextField .title .pessoasFoto{
    width: 35%;
}

.TextField .title .segurancaFoto{
    width: 60%;
}

.TextField .title .servicoFoto{
    width: 50%;
}

.TextField .title .sustentabilidadeFoto{
    width: 40%;
}

.TextField .title h1 span{
    color: #000177;
    margin-left: 10px;
}

.TextField .content{
    width: 100%;
    padding: 0 8%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.TextField .content .text{
    width: 46%;
}

.TextField .content .text .pfirst{
    margin: 0;
    margin-bottom: 20px;
}

.TextField .content .text .plast{
    padding: 0;
    margin: 0;
}

.TextField .content .imgText{
    width: 46%;
}

.TextField .content .institucionalFoto{
    background: url(../imgs/sobre.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.TextField .content .pessoasFoto{
    background: url(../imgs/pessoas.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.TextField .content .segurancaFoto{
    background: url(../imgs/cipa.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.TextField .content .sustentabilidadeFoto{
    background: url(../imgs/meioambientes.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.TextField .content .transparenciaFoto{
    background: url(../imgs/Transparencia.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (max-width: 800px) {
    .TextField .content .imgText{
        width: 100%;
        height: 300px;
    }
    .TextField .content .text{
        width: 100%;
        margin-bottom: 30px;
    }

    .TextField .title .institucionalFoto{
        width: 75%;
    }

    .TextField .title .pessoasFoto{
        width: 95%;
    }

    .TextField .title .segurancaFoto{
        width: 90%;
    }

    .TextField .title .sustentabilidadeFoto{
        width: 98%;
    }

    .TextField .title .contato{
        width: 80%;
    }

    .TextField .content .FALE{
        width: 100%;
        height: auto;
    }
}